.contactPage h1::before {
  content: "<h1>";
  font-family: "La Belle Aurore";
  font-size: 20px;
  position: absolute;
  top: -40px;
  left: -10px;
  opacity: 0.8;
  line-height: 18px;
}
.contactPage h1::after {
  content: "</h1>";
  font-family: "La Belle Aurore";
  font-size: 20px;
  position: absolute;
  left: -30px;
  bottom: -40px;
  margin-left: 20px;
  opacity: 0.8;
  line-height: 20px;
}
.contactPage p:first-of-type {
  margin-top: 10px;
  color: #fff;
  font-size: 16px;
  font-weight: 300;
  line-height: 24px;
  min-width: fit-content;
  font-family: sans-serif;
  animation: fadeInLeft 4s;
}
.contactForm {
  width: 100%;
  margin-top: 20px;
}
.contactForm ul {
  padding: 0;
  margin: 0;
}
.contactForm li {
  padding: 0;
  margin: 0;
  list-style: none;
  margin-bottom: 10px;
  /* opacity: 0; */
  overflow: hidden;
  display: block;
  clear: both;
  position: relative;
  /* animation: fadeInUp 1.5s 1.5s; */
  /* animation-fill-mode: forwards; */
}

.contactForm li.half {
  width: 49%;
  margin-left: 2%;
  float: left;
  clear: none;
}
.contactForm li:first-child {
  margin-left: 0;
}

input[type="text"],
input[type="email"] {
  width: 100%;
  border: 0;
  background: #113a50;
  height: 50px;
  font-size: 16px;
  color: #fff;
  padding: 0 20px;
  box-sizing: border-box;
}

.contactForm textarea {
  /* width: 100%;
  border: 0;
  height: 50px;
  
  color: #fff;
  padding: 20px;
  box-sizing: border-box;
  min-height: 150px; */
  font-size: 16px;
  background: #113a50;
}

.contactForm .flatButton {
  color: #fff;
  font-size: 11px;
  letter-spacing: 3px;
  text-decoration: none;
  padding: 10px 12px;
  border: 1px solid #113a50;
  float: left;
  border-radius: 4px;
  background: 0 0;
  text-transform: uppercase;
  float: right;
  text-align: center;
  margin-right: 10px;
}
.contactForm .flatButton:hover {
  cursor: pointer;
  background: #113a50;
  border-color: #113a50;
  font-weight: 400;
}

.infoMap {
  /* position: absolute; */
  background-color: #000;
  /* top: 20px;
  right: 5%; */
  width: 280px;
  padding: 20px;
  color: #fff;
  font-family: Helvetica;
  font-size: 17px;
  font-weight: 300;
  /* opacity: 0; */
  /* animation: fadeIn 1s 3s; */
  /* animation-fill-mode: forwards; */
  z-index: 9999;
}

.infoMap h6 {
  font-family: Helvetica;
  font-size: 16px;
}
.infoMap span {
  color: var(--c-secondary);
  font-family: Helvetica;
  font-size: 16px;
  font-weight: 600;
}
/* styles for map */
.mapContainer {
  /* background: rgba(8, 253, 216, 0.1); */
  float: right;
  /* width: 53%; */
  height: 100%;
}

.leaflet-container {
  position: relative;
  width: 100%;
  height: 100%;
  min-height: 400px;
  /* opacity: 0; */
  /* animation: backInRight 3s 1.2s; */
  /* animation-fill-mode: forwards; */
}
.leaflet-popup-content {
  color: #000;
  padding: 2px;
  font-size: 16px;
  font-weight: 400;
  font-family: Helvetica;
}

@media only screen and (max-width: 768px) {
  .leaflet-popup-content {
    width: 200px;
  }
}

@media only screen and (min-width: 1280px) {
  .leaflet-container {
    min-height: 80vh;
  }
}
