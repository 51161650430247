.feedbackPage h1::before {
	content: "<h1>";
	font-family: "La Belle Aurore";
	font-size: 20px;
	position: absolute;
	top: -40px;
	left: -10px;
	opacity: 0.8;
	line-height: 18px;
}
.feedbackPage h1::after {
	content: "</h1>";
	font-family: "La Belle Aurore";
	font-size: 20px;
	position: absolute;
	left: -30px;
	bottom: -40px;
	margin-left: 20px;
	opacity: 0.8;
	line-height: 18px;
}
.feedbackPage p {
	margin-top: 15px;
	color: #fff;
	font-size: 16px;
	font-weight: 300;
	line-height: 24px;
	min-width: fit-content;
	font-family: sans-serif;
}
.feedbackPage .feedback_first_col p {
	animation: fadeInLeft 4s;
}
.feedbackPage label[for="rating"] {
	color: #fff;
	font-size: 16px;
	font-weight: 300;
	line-height: 24px;
	font-family: sans-serif;
}
