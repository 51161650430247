.textAnimate {
	display: inline-block;
	opacity: 0;
	animation: bounceIn;
	animation-duration: 1s;
	animation-delay: 1s;
	animation-fill-mode: forwards;
	min-width: 10px;
}
.textAnimateHover {
	min-width: 10px;
	display: inline-block;
	animation-fill-mode: both;
}
.textAnimateHover:hover {
	animation: rubberBand 1s;
	color: #fff !important;
}
.textAnimate._1 {
	animation-delay: 0.1s;
}
.textAnimate._2 {
	animation-delay: 0.2s;
}
.textAnimate._3 {
	animation-delay: 0.3s;
}
.textAnimate._4 {
	animation-delay: 0.4s;
}
.textAnimate._5 {
	animation-delay: 0.5s;
}
.textAnimate._6 {
	animation-delay: 0.6s;
}
.textAnimate._7 {
	animation-delay: 0.7s;
}
.textAnimate._8 {
	animation-delay: 0.8s;
}
.textAnimate._9 {
	animation-delay: 0.9s;
}
.textAnimate._10 {
	animation-delay: 1s;
}
.textAnimate._11 {
	animation-delay: 1.1s;
}
.textAnimate._12 {
	animation-delay: 1.2s;
}
.textAnimate._13 {
	animation-delay: 1.3s;
}
.textAnimate._14 {
	animation-delay: 1.4s;
}
.textAnimate._15 {
	animation-delay: 1.5s;
}
.textAnimate._16 {
	animation-delay: 1.6s;
}
.textAnimate._17 {
	animation-delay: 1.7s;
}
.textAnimate._18 {
	animation-delay: 1.8s;
}
.textAnimate._19 {
	animation-delay: 1.9s;
}
.textAnimate._20 {
	animation-delay: 2s;
}
.textAnimate._21 {
	animation-delay: 2.1s;
}
.textAnimate._22 {
	animation-delay: 2.2s;
}
.textAnimate._23 {
	animation-delay: 2.3s;
}
.textAnimate._24 {
	animation-delay: 2.4s;
}
.textAnimate._25 {
	animation-delay: 2.5s;
}
.textAnimate._26 {
	animation-delay: 2.6s;
}
.textAnimate._27 {
	animation-delay: 2.7s;
}
.textAnimate._28 {
	animation-delay: 2.8s;
}
.textAnimate._29 {
	animation-delay: 2.9s;
}
.textAnimate._30 {
	animation-delay: 3s;
}
.textAnimate._31 {
	animation-delay: 3.1s;
}
.textAnimate._32 {
	animation-delay: 3.2s;
}
.textAnimate._33 {
	animation-delay: 3.3s;
}
.textAnimate._34 {
	animation-delay: 3.4s;
}
.textAnimate._35 {
	animation-delay: 3.5s;
}
