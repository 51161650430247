.container {
  width: 100%;
  will-change: contents;
  min-height: 566px;
  position: absolute;
  opacity: 0;
  top: 10%;
  margin: 0 auto;
  z-index: 1;
  transform-style: preserve-3d;
  animation: fadeIn 1s forwards;
  animation-delay: 1s;
  max-width: 1536px;
}

@media only screen and (min-width: 600px) {
  .container {
    height: 90%;
  }
}
