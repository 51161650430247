.aboutPage h1::before {
  content: "<h1>";
  font-family: "La Belle Aurore";
  font-size: 20px;
  position: absolute;
  top: -40px;
  left: -10px;
  opacity: 0.8;
  line-height: 18px;
}
.aboutPage h1::after {
  content: "</h1>";
  font-family: "La Belle Aurore";
  font-size: 20px;
  position: absolute;
  left: -30px;
  bottom: -40px;
  margin-left: 20px;
  opacity: 0.8;
  line-height: 18px;
}
.aboutPage p {
  margin-top: 15px;
  color: #fff;
  font-size: 16px;
  font-weight: 300;
  line-height: 24px;
  min-width: fit-content;
  font-family: sans-serif;
  /* opacity: 0; */
  /* animation: fadeInUp 3s; */
  /* animation-fill-mode: forwards; */
}
.aboutPage p:nth-of-type(1) {
  animation-delay: 0.1s;
}
.aboutPage p:nth-of-type(2) {
  animation-delay: 0.3s;
}
.aboutPage p:nth-of-type(3) {
  animation-delay: 0.5s;
}
.aboutPage p:last-of-type {
  color: #687277;
}
.aboutPage a {
  font-family: sans-serif;
  line-height: 24px;
}
.textAnimateHover:hover {
  color: #fff;
}
/* .cubeContainer {
  width: 50%;
  height: 100%;
  padding-top: 15%;
  margin-left: 0;
  position: absolute;
  top: 0;
  right: 0;
  overflow: hidden;
} */

.cubeSpinner {
  animation-name: spincube;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
  animation-duration: 10s;
  transform-style: preserve-3d;
  transform-origin: 100px 100px 0;
  margin-left: calc(50% - 100px);
}
.cubeSpinner div {
  position: absolute;
  width: 200px;
  height: 200px;
  border: 1px solid #ccc;
  background: rgba(255, 255, 255, 0.4);
  text-align: center;
  font-size: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 0 20px 0px lightyellow;
}

.face1 {
  transform: translateZ(100px);
}
.face2 {
  transform: rotateY(90deg) translateZ(100px);
}
.face3 {
  transform: rotateY(90deg) rotateX(90deg) translateZ(100px);
}
.face4 {
  transform: rotateY(180deg) rotateZ(90deg) translateZ(100px);
}
.face5 {
  transform: rotateY(-90deg) rotateZ(90deg) translateZ(100px);
}
.face6 {
  transform: rotateX(-90deg) translateZ(100px);
}

@keyframes spincube {
  from,
  to {
    transform: rotateX(0deg) rotateY(0deg) rotateZ(0deg);
  }
  16% {
    transform: rotateY(-90deg);
  }
  33% {
    transform: rotateY(-90deg) rotateZ(90deg);
  }
  50% {
    transform: rotateY(-180deg) rotateZ(90deg);
  }
  66% {
    transform: rotateY(-270deg) rotateX(90deg);
  }
  83% {
    transform: rotateX(90deg);
  }
}
