.workPage h1::before {
  content: "<h1>";
  font-family: "La Belle Aurore";
  font-size: 20px;
  position: absolute;
  top: -40px;
  left: -10px;
  opacity: 0.8;
  line-height: 18px;
}

.workPage h1::after {
  content: "</h1>";
  font-family: "La Belle Aurore";
  font-size: 20px;
  position: absolute;
  left: -30px;
  bottom: -40px;
  margin-left: 20px;
  opacity: 0.8;
  line-height: 18px;
}
