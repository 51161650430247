@import "animate.css";
@font-face {
  font-family: "Helvetica Neue";
  src: url("./assets/fonts/helvetica-neu.ttf") format("ttf");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "La Belle Aurore";
  src: url("./assets/fonts/LaBelleAurore.woff2") format("woff2"),
    url("./assets/fonts/LaBelleAurore.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Coolvetica";
  src: url("./assets/fonts/CoolveticaRg-Regular.woff2") format("woff2"),
    url("./assets/fonts/CoolveticaRg-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
/* body {
  overflow: hidden;
} */
input,
textarea {
  font-family: "Helvetica Neue", sans-serif;
}
