@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  font-size: 62.5%;
}

body {
  margin: 0;
  font: 300 11px/1.4 "Helvetica Neue", sans-serif;
  color: var(--c-textColor);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: var(--c-primary);
  /* overflow: hidden; */
  display: block;
}

/* :root {
  --c-primary: #022c43;
  --c-secondary: #ffd700;
  --c-sidebar: #181818;
  --c-icon: #4d4d4e;
  --c-textColor: #444;
  --c-lightGrey: #8d8d8d;
} */
:root {
  --c-primary: #212121;
  --c-secondary: #05c7b8;
  --c-secondaryLight: #1ed1c3;
  --c-sidebar: #211d1d;
  --c-icon: #0b6480cc;
  --c-textColor: #444;
  --c-textColorDark: #282525;
  --c-lightGrey: #8d8d8d;
}
/*
:root{
    --c-primary: #222831;
    --c-secondary: #00ADB5;
    --c-sidebar: #393E46;
    --c-icon: #EEEEEE;
    --c-textColor: #00ADB;
    --c-lightGrey: #00ADB5; 
};
 */
